import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { TranslocoService } from '@jsverse/transloco';
import { DetailDialogResponseType } from 'app/components/detail-dialog/detail-dialog.types';
import { UtilsService } from '../utils';

@Injectable({
    providedIn: 'root',
})
export class FuseConfirmationService {
    private _matDialog: MatDialog = inject(MatDialog);
    private _utilsService: UtilsService = inject(UtilsService);
    private _translocoService = inject(TranslocoService);

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    open(
        config: FuseConfirmationConfig
    ): MatDialogRef<FuseConfirmationDialogComponent, DetailDialogResponseType> {
        // Merge the user config with the default config
        this._mergeUserConfigWithDefaultConfig(config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !config.dismissible,
            data: config,
            panelClass: 'fuse-confirmation-dialog-panel',
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    private _mergeUserConfigWithDefaultConfig(config: FuseConfirmationConfig): void {
        if (this._utilsService.isNullOrUndefined(config.icon)) {
            config.icon = {
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            };
        }

        if (this._utilsService.isNullOrUndefined(config.actions)) {
            config.actions = {
                cancel: {
                    label: this._translocoService.translate('COMMON.CANCEL_LABEL'),
                },
                confirm: {
                    label: this._translocoService.translate('COMMON.DELETE_LABEL'),
                    color: 'warn',
                },
            };
        }

        if (this._utilsService.isNullOrUndefined(config.actions?.confirm)) {
            config.actions.confirm = {
                label: this._translocoService.translate('COMMON.DELETE_LABEL'),
                color: 'warn',
            };
        }

        if (this._utilsService.isNullOrUndefined(config.actions?.cancel)) {
            config.actions.cancel = {
                label: this._translocoService.translate('COMMON.CANCEL_LABEL'),
            };
        }

        if (this._utilsService.isNullOrUndefined(config.dismissible)) {
            config.dismissible = false;
        }
    }
}
